// ℹ️ Type-only import: Do not remove 'type' from 'import type' here.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
import { TRPCClientError } from '@trpc/client';
import { TRPCBase } from '~/server/trpc';

export type ApiError = TRPCClientError<TRPCBase>;

export function isTRPCClientError(cause: unknown): cause is ApiError {
  return cause instanceof TRPCClientError;
}
