/**
 * Utilities for dealing with USD amounts.
 *
 * USD-amounts are represented by a `bigint` with **2** decimal points padded at the end.
 */
import { z } from 'zod';
import { round } from './format';
import type { Maybe } from './utility';

function assertNumber(value: unknown): asserts value is number {
  if (typeof value !== 'number') {
    throw new Error(`Expected a number, got ${typeof value}`);
  }
  if (Number.isNaN(value)) {
    throw new Error(`Expected a number, got NaN`);
  }
}
function assertBigint(value: unknown): asserts value is bigint {
  if (typeof value !== 'bigint') {
    throw new Error(`Expected a bigint, got ${typeof value}`);
  }
}

/**
 * Convert a `number` to a `bigint` with `2` decimal points padded at the end.
 */
function fromDecimals(amount: number): bigint {
  assertNumber(amount);
  return BigInt(round(amount * 100, 0));
}
/**
 * Convert an USD-amount in `bigint` to a `number` with `2` decimal places.
 * @example 100 => 1.00
 */
function toDecimals(amount: bigint): number {
  assertBigint(amount);
  return round(Number(amount) / 100, 2);
}

function toCents(amount: bigint): number {
  return toDecimals(amount) * 100;
}

/**
 * Helper for user-facing display of USD amounts.
 * Convert a `bigint` to a a USD-string with a currency mask ($).
 * Ex: 5045 => $50.45
 */
function toCurrencyString(amount: Maybe<bigint>): string {
  if (amount == null) {
    return '';
  }
  return formatter().format(toDecimals(amount));
}

function formatter(opts?: { maximumFractionDigits: number }) {
  const { maximumFractionDigits = 2 } = opts ?? {};
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  });
}

/**
 * Helper for user-facing display of USD amounts.
 * Convert a `bigint` to a a USD-string with no currency mask ($).
 * The two last digits of the bigint are for cents
 * Ex: 100023 => 1,000.23
 */
function toNoCurrencyString(amount: Maybe<bigint>): string {
  if (amount == null) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(toDecimals(amount));
}

/**
 * Helper for user-facing display of USD amounts.
 * Convert a decimal `number` to a a USD-string with a currency mask.
 */
function toCurrencyStringFromDecimals(amount: Maybe<number>): string {
  if (amount == null) {
    return '';
  }
  return usdAmount.toCurrencyString(usdAmount.fromDecimals(amount));
}

/**
 * Helper for using USD-amounts in `<input type="number" />`.
 * Converts a `bigint` to a `string` with 2 decimal places
 */
function toInputString(amount: Maybe<bigint>): string {
  if (amount == null) {
    return '';
  }
  return toDecimals(amount).toFixed(2);
}

const createDecimalStringSchema = (opts: {
  /**
   * Minimum amount allowed in dollars and cents.
   * @example 0.01 - means minimum of 1 cent
   */
  min: number;
}) =>
  z
    .string()
    .min(1, 'Required')
    .regex(/^(\-)?\d+(\.\d{1,2})?$/, 'Must be a number with maximum 2 decimals')
    .transform(parseFloat)
    .pipe(
      z
        .number()
        .min(opts.min, `Must be at least $${opts.min.toFixed(2)}`)
        .transform(fromDecimals),
    );

const frequencySuffix = {
  WEEK: '/week',
  'BI-WEEKLY': ' every 2 weeks',
  MONTH: '/month',
  QUARTER: '/quarter',
  YEAR: '/year',
} as const;
/**
 * @return $12.34/month or $12.34 every 2 weeks
 */
function toCurrencyStringWithFrequency(
  amount: bigint,
  frequency: 'WEEK' | 'BI-WEEKLY' | 'MONTH' | 'QUARTER' | 'YEAR',
) {
  return `${toCurrencyString(amount)}${frequencySuffix[frequency]}`;
}

export const usdAmount = {
  fromDecimals,
  toDecimals,
  toCents,
  createDecimalStringSchema,
  toInputString,
  toCurrencyString,
  toCurrencyStringWithFrequency,
  toCurrencyStringFromDecimals,
  toNoCurrencyString,
  formatter,
};
